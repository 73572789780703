import React from "react";
import {Button, Checkbox, Form, Input, Modal, Select} from 'antd';
import {
    Autocomplete,
    DirectionsRenderer,
    DirectionsService,
    GoogleMap,
    InfoBox,
    KmlLayer,
    LoadScriptNext,
    Marker,
    MarkerClusterer,
    // Polyline
} from '@react-google-maps/api'
import {MyTripContext} from "../../context/MyTripContext";
import {trackViewMember} from "../../services/gtm";
import language from "../../../data/language.yaml";
import Link from "../Link";
import Img from "gatsby-image";

class Map extends React.Component {
    loadScriptLibraries = ["geometry", "places"];

    amenities    = {};
    autocomplete = undefined;
    directions   = undefined;
    kmlFiles     = undefined;
    map          = undefined;
    mapOptions   = undefined;
    markers      = [];
    members      = [];
    regions      = [];
    settings     = {
        bounds:    {
            east:  -2.590704,
            west:  -5.280711,
            north: 53.442918,
            south: 51.317567
        },
        polylines: [
            'koraItqjUpGpS|@bCqHdOaKnYqJnIeIzDyKcHmOeSyA_ZwF}UsNgLsPyh@aSyNyDoLmCy[oScXsg@waAqa@{k@{[kj@cLcIiIsMsPuWuLsZya@{_AcI~WcVnt@uQ|FqKxGcg@h\\iUt]uVtj@cKtGuIbWiHpQgL|GuErNcAxScLro@sDbm@cEfg@Yjg@qLfuAkNru@cDtj@\\pa@gEhl@k_@vf@yDrH`AzJbH^`GmErLgA|LkDjJmHnKQbKpGzJ~^rHrOr@l`@p@nt@jArp@~Lfd@fNvi@yB`^aI~SsDf\\pBxf@oBpVl@~TxIbUhDviAoDlt@eGhn@a@hi@{Ilj@_J~^yNz[}MbWkStR}D|RaJl\\qI`EyDnNiDhXvCrQfA|SpAzv@b@xw@_KzX_NlGw@tL\\fX_FtPPbRdIjc@lCnf@xCbWU`IgEnG{AfM{TmMiLlDwQzk@oC~d@dCrPyBrGyM_IoJrDqP|ZsLhV{N|DcFzD}DsBsP}Ho`@cn@uHgFE{E?yC{GaBsMvAwQr@gNgAsMzAr@yOvEcGrElBl@rEs@]j@mBmEaD{ElE{BtIuB~HyHpBoP~G}XkHgWm]yVgHyYoOa^gb@ol@a`@}N}e@eNgXqA}OkIyHoTwJsO{PHqy@yFuw@oIoy@ui@sg@}G}\\wMcTkHik@eFsu@i@mh@~BaUiCsWtCkp@hEac@qFqn@kKqnAsHyI{MiAcCcEsE}@sa@G}UMq^cAe[}JaIyR_QwGc[aG_TsNsUmIyh@iHkSzCw@cCeC{DkGqHeDoH_AuBcLzHgQpIKrEnJzG`CxH~RmEfc@cJjh@dGvVhItSrN`n@tOdH`Sp[vK~\\x@zEfAX|C[tCuGlD{LjO{JxB_JxRkFvM]qDyDuHmHeCwOwQgPmLqQcJu]iDi[oLuXpA_]zCsSlKwS`QaJt@yEnJaFhh@oQjQgRv]_EtNcHpFaPbDiGrEoFnLmKvDiOQwYhDkHRoK_CuGa@qGfCkGbFeLu@yWdJyLlJmPlCgSpM_MlWoJ|Do[~W{OlI`BgJ{@wK_@yFvCwFwCvF^xFXlDc@jMwHfE}b@`DyTrNqK~CoKuB_a@iDgIsAcJyIeDoMcIwJcRsEuOuEwKlA{WNi@}Nb@a]sLqb@m@aW~HiR_EcHoHsVaNyc@cLaKiVeDuUmp@oSbImU_@_H{BuEsOuG{E}JpAcMnBmKtO{Y`PmGsBm[gUqHuBeLtIoHcCsGsRgPgLY}@kCmUqVgaAuf@atAwDsRqG_WcEcp@c@yJmBk@eB|IGd@',
            'eahdIv~zUdB}PlBiCm@gAaK~GuB|AvEqk@^qOc@wFqJwJABCBC@KGAQuJsEeF\\}HfNcBjJtDvSo@lQcChEBb@OZyYdZsJxNgEhRvB~M}Yz}@eFzOGVeA|AgFtW}JrQmBfEaKfTwJfDyGz[a@xUpGtdBv@fv@c@|AkD~g@}R`v@kG~Br@~TvO`WrHfGtBnOnAbLjDoFhC{CDa@vQ_PhX_b@xAoHzQwGxN}BjLtKtJrLzJtCf]ObFmCbIu^jWoj@fZe`@tAu[vFzN|S`u@f@zKA`@l@nTaW`d@}Gz]sAhv@aHhLgF~ErAzOfEdYhBzWmCvqAjAfh@pG|\\l\\lbA`Yrx@f\\~iAtU~nApF~l@dMjkBjIxm@bPle@jA`DfMho@`W~p@|SnZ~QpO|f@nm@v]lbA~Nnu@jy@xnEfz@p~Bza@nxBbKdf@vNrWpGoFrJmh@hIem@`Bu]xKsFhFwOlMuPvPlCnQgGlYuj@pRsj@~E}JrEyUjXaJhGgB`A~Fr@@zJnH|ArJlFfLuB_FcCqLzCQtJoL|BiMx@_D}@`E}BvLgDnHoJnC~FpSmKgZ}HuFcBq@kCwCwF|AaS~FkAnAmCdJuC`LcJj^m_@fx@_Q|OmQaDcL`FqNl[cKtFaCt`@}Hpp@{Hra@Gl@yDzJeI~FsJ`B}G{DyRiSg@VgLqMmLTaMzQUl@iRnQ{KbJw\\tFuIzHq@nKwOrc@AN]pLb^~T?VlFjG|BaDqBaGqErDdT``@v@EVLAb@~D|FpD{K`B|ImFlMqO{CCLO?COyH{@cCdK|AvOrLl\\bKnZhJxe@dUbd@TjG{NbPaGxHa@{AeJmKyFaBnBiDxHcAa@pGmC|@cHgBgQaNqQyIwFcEoDcMeIwVaXoaAu_@ohAiLqVoFsWyJsX_H}[gRej@aU{ZkWm`@gDsT{AiDqCd@y@iAO}CdCh@`B|GwBvD{EhMIrI}@`j@gAlZaGbqAu@~V|CvSfDfv@@pc@_Dnt@o@zK{G`I}FpQcSpp@sFxCs@vI|BtZeM|o@c@nUeGzW}Pvx@{IjHcB|TRxT{C`]xDzX}KrOk[lCuF`FyPfd@}EhCmIeHeWuZul@gIsSrB_LjKaYb[qFfDsG?sN}HiMgBsJxC}M|N_Yl^cNnKiQfY{WtXePbWrN~YjCdV}EdSeVpo@oKhPwYrVcLvKw@pQgOpd@cNb_@{D``@{Njd@gOh^mKaB{RtJqHbMwPzK}KbCePdFmYnOeKxMgHnB}QzAmM`\\_\\ju@_K|_@oNdN{RfKw@pCSVqKoHiVaQgEwPa@eH',
            'cb_eIlqmYZbNvNtSrYhPbb@lWhc@wMtI|AfLbUz^jm@~b@zTlp@xf@vOfIzTjG~Ig@dPiArKuHre@ZlL^~FtFrN`HvNfFdi@`VrI|J~Rx@~JlAFCDLpJte@zPtvAlF|O|L~PxT`e@vEbXfL|ZzPnUrPpInStNzQd[n\\bb@~XxO|JxHfChQVda@rEvv@pAzlAUzR{GtZmHnt@iEhRi@vg@t@vh@mPr`A}I~XVxU~Ab_@c@`Xfj@feAxMdb@jZvy@`FbMjChOiJvc@q[nzA{Ovu@st@r_AeDbT?f_A?tRw@r@sGtUaC|XgNz]\\lK}FpAgJhEcChDyApEc@bX_BnOXjLBp@f@zu@}DrNvDxPtMxt@tOfUbIhHhLvTfHlGuEvM_Kh`@eGfZkJ|\\bCcElDuQfH}TjDi\\|G_RzA}DfAgCdB|CjAPlC_DdI_FdH_B|DgBrEyBrDtDdB{DP{R_CwOPs^xIcLr@qOzB_L~EwAxHyf@jKuc@Mi`@tGyJ~LqIrD_CL_HgDwJz@oOnEiUvBqHeDkSg@yP|NgLxJgZhJc_@|Ga^`GuFxEmXoBga@cJag@{LsZaT}VqCk]uH}b@k@aI}C}HeBqMdIm_@dCuKnCoHqEof@vBiiAzSepA|Cel@r_@ivDzDmXvD_X_BI|BwEl@sOjBo^rUkkAbAqDvEfE`CpCTMbEfBb@UbSzK`NfIlMtLnHpKtNlI|WlBlNzCzN`IjKdMdPbUfKrIlIxPPrMjBvSh@lCZuEdCiHlInKdG~Dh@oLtCyBr@RoD^gBro@a@fQOpBnGnMdRrOdUdId]bJfW_InPsRrQ{n@pKcRhEuTQme@UwKxIyT~j@il@o@}c@yBe\\iJi|DbJqRlHs^~@k_@}Yw_@mIaJ|@}JzHkTvRwy@dPo\\zG}LdJ}Bbk@kGxRqC`MkV|TqGvIoPlBmNhPmd@bYgq@pEmJ|IsD\\}@eCuKsf@aqAdGeRlJej@w@qb@aG__@mVuZaDsBZeGn@uKt@eL|Eyc@~Num@nA{QfHaDnGmJhOo^qSsOyBsDuD|EXzEY{EtAqI{CiDkDgDmCvNc@lFmEaBqIqH{GAiH}@mE{EsMqIoQ{OiLgI_HuQg@kOeFsK}O}NgUkg@mM_XuKad@yMqd@qPe]sSmk@or@yn@yUyVid@ox@wCgMzAwPi@s`@aBgSFqErHvCtL_JnRk[`d@ue@~B{RxLbNlWjSpNzN|LtCrBoAPnDRvEpHhR~L`RpDwHxA}@HbAt@zIxEbK`HjDxFPbFbLrDrIxBa@pF|DvDqDjJjA',
            'suqcI|wrXuPbBqFeDaIqK{V{QmF_TcHdIiYgk@u@}BjC~@lAnJdTta@rBaCzCwMbDi]pHoQzHqn@v_@siA~J{IlNqBhZwZ~Yq]zUiRn`@c^nRqA~\\vj@zb@fk@|PhN~Ql@fPzI~Nul@bJoNlRuQnJgo@|GuGnI}VjCcWxLsLb`@al@fF_BHKB\\KC_HzCu`@rk@iLhNsBt[yQfYkLzp@sQtPaJlRoS`t@}Rn`@qVnb@cFnf@kKndBhBtl@d@bq@|I|aAvHzwArKtf@n@nvAdIjT|A`WiHr`@hFtYtBzQXvE`CqGt@o@?E@GDEHFAPK@kCfGb@xL_AbDaBgI`GaPBE?IHGdMsThQ~G~G}GQuB`M_DnHsF`]Dre@TxNt@lo@zMre@ri@lFr@jH~EzV~R`f@cAtd@gAlQnGfw@|CgEvA|BIk@{Aet@q@qAdc@}EbRvGtYsIrk@vE~OjVtVlkA|gAnjAdgAf`Axz@~n@nw@`_A|iA|g@dh@|h@zpAtLnb@dWnW|M`Kvt@~rAxQbJrYp@vb@yWDgAtLkVrb@cGbiAeWh_@aKxh@yFyHwYbJ~UiXnDeoAn\\e{@bQkKdNrTrq@`T`ZzPtb@rEzDtErTrH`\\zMjwAn@jScEpCuVpUg[y@yJ|BoA{EiA}AuFtFaIh[K|E?cL`NkZzAg@rB`@z@~FfMsAnMnB|MmE`SsR|E}AnClNwBpl@zZjuA`JvP|IzA~NtGcCdo@pBn_@nNjl@Kn[xZbm@`k@p`AhAFHDdN|Cx]|_@`Uzo@xQzYbZzl@zL`~@`Mxf@hMnHzCbOfQ`n@jRzO~UYrQjRx^xLfYzT`k@fc@hIrQx[`{@|c@|o@bQ`KtOg@~GvBzAnFpAfMeDjK}BrOnGbH|Fh^jRd]pWr_A~_@|}@fH`n@bKdYnd@tn@jg@nj@r[nRx\\|Vl`@to@hRpu@hQnX|H`MrIl@pO`Ie@rF}DxD{KjH}InIuF{SgT_uAsVmo@uc@gk@c{@wk@ue@sl@ec@es@wLo~@}c@q`AaTgz@{Pe^yKu]OcLjGyMpGkQlPge@fU{}@x\\i~ApAwNoFgL{Fii@wUagAK_kALeWvKuLbHuYnOac@oBcn@}OgWyBe_@mT}|@uSui@iC}iAfHcNJ{Oqu@imAei@iJwh@ugAsLoaAmUyaAsPu`BSaF]cEsM{OKRkF}n@sWcc@sDac@{Re\\yE}kAkAmKfA}IxAgbA_Jof@gN{cAyA_f@cIoYoAkAi_@e}@cLe|AoKkuB{VinAaBsa@hBia@mBnBmCnJ',
            'kg_bIx}`YrKu}@l@k~ACsy@wI{vAmSao@gT{^{d@}oAuE_mC~FcqBn\\onAxE_s@UOhBmDl_@yvAbKijAsPsl@cGo}@tWtTdOpBpNrDdCxQiNo@{BcImRkQ}XaWyHkk@gI}p@sM}n@ev@}fBuNo_BzH{g@cAok@{OipAcNgT`IrO`HhDhGjx@`I~l@yJtl@|Axx@zD``@~Uhv@r^x}@`KjJfByXhMo]vUeItRd@fLgVdMZ~\\bEpZ~a@xPhc@`XbUlYjV|NiBxTyJlQxo@|NlWnQ|AtMlEjLaEfQ|FvUjTrVni@n\\vt@rOps@@jDrGhIjf@zG`\\~PfMxJ~GgKhUySbQPjf@mUnh@oi@`PuN~PtG~HtKtPaKl^Kfr@aGf^qB`ZaL~g@qIta@y@bNeRxe@cPff@{]f]oIxVgQln@kO`m@iVzWm`@nTsSnQmZhNiUnUiG`OmUzCoUm@qc@wGcPhBaVk[ab@uSyFa\\qZgO}UeJil@aR}s@sKsKd@sOlCot@kK{Y{U_LqDi\\tE{MQmZiHyMwJk]uEu_@M}w@iGe`Ada@wv@dL|b@fKbn@pTre@j\\n`@`Gxp@pL|k@lp@lsAxd@jnApg@xb@dQ|dAdY~c@`OjNZlW?r\\`HhUhOph@tYfAxQxLhOje@tVzs@d\\lu@~]xj@bUp\\xZfJ`SnIbRrYx]`jAhUxVf\\bMnp@z]je@zOwAiSlM{e@dN}yAv@wh@fPkn@d_@i_@bXxIhMwP|[i}AnRiEjHnInu@nkArl@fiArPbPjImChEzKZhYwHzZ[r@q@iAtAcGtFqz@x^ip@fZ_Ud_@iF`TfL`\\_Cf_@fBn_@eMnVwFdIw`@rJmrB_Mg{@fBsReHmn@uDeeAwRchAkNwh@a@c^qJw]}Dsl@rCce@}Qsg@}`@_n@{c@kZmg@_]kSih@uWya@yD}b@yCs|@}Kq~B_Emy@uPihA_t@erBuGix@qIm^eJmBwLtQqP_HuVyAyVxGePbNqH_F_LmZsQ~FyWyAsEk[{BmUwTgScF`ViU`e@kg@ScSqKoPjC}BnTsLhXeRvCiNdQmFv[sBvZkPpPio@vf@sTzd@_SnAsZwOyU}NsGgb@yy@olAmt@wqAk_@qMkx@mp@ov@qWgUrCeLrQ}McBei@bv@uNfQyGv`@`AjTr^jaBuE~s@ofAuaD}Ycb@{C{y@gPg_AwKi~A_KmbA_`@_hAgWoRacAisCaj@ymA}c@eo@yxB}`De`@ah@__@sI{b@wSoVeSeSoZaL__@m_@wu@sp@y_@|GgJbNao@aEsN'
        ],
        routes:    {
            1: {
                origin:      {
                    // query: "Bala Lake Railway, Station Rd, Llanuwchllyn, Bala LL23 7DD",
                    placeId: "ChIJ_QV0-4VCZUgRYyoHU662c0I"
                },
                destination: {
                    // query: "Welsh Mountain Zoo, Colwyn Bay LL28 5UY",
                    placeId: "ChIJU4vclZwfZUgRILJuX9jLp_U"
                },
                waypoints:   [
                    {
                        location: {
                            // query: "Llechwedd Slate Caverns, Blaenau Ffestiniog",
                            placeId: "ChIJ6VQ1zhYNZUgRWINEcJdNNCQ"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Zip World Fforest, A470, Betws-y-Coed",
                            placeId: "ChIJEyNIf7gWZUgRcHmbQHvWMIE"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Adventure Parc Snowdonia, Conway Road, Dolgarrog, Conwy",
                            placeId: "ChIJFb9d7TAaZUgRLufOuY7j6Yc"
                        },
                        stopover: false
                    }
                ]
            },
            2: {
                origin:      {
                    // query: "Welsh Mountain Zoo, Colwyn Bay LL28 5UY",
                    placeId: "ChIJU4vclZwfZUgRILJuX9jLp_U"
                },
                destination: {
                    // query: "Amlwch Port",
                    placeId: "ChIJnz-HiYBRZEgRH7i9FTgNuLA"
                },
                waypoints:   [
                    {
                        location: {
                            // query: "Marine Dr, Colwyn Bay",
                            placeId: "ChIJ3xcPIyggZUgR1nP23Eyeqcc"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Great Orme Tramway, Church Walks, Llandudno",
                            placeId: "ChIJ8xthZAMeZUgR2_zSWXFoFxk"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Conwy Castle, Rose Hill St, Conwy",
                            placeId: "ChIJbRfN1IUeZUgRDPlJPV16QEw"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Puffin Cafe, Penmaenmawr",
                            placeId: "ChIJq6xDRvwcZUgRrtNZW7K6IFw"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Zip World Penrhyn Quarry",
                            placeId: "ChIJCQaf4T8GZUgRr9wV3nwobi0"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "STORIEL, Ffordd Gwynedd, Bangor",
                            placeId: "ChIJzd5uikkHZUgR6c_mjUJ25BQ"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "RibRide, Porth Daniel Watersports Center, Water Street, Menai Bridge",
                            placeId: "ChIJ53nRtvupZUgRHchuMtYIxK8"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Beaumaris Castle, Castle St, Beaumaris",
                            placeId: "ChIJZY7mehABZUgR0Lo3lSoLbEc"
                        },
                        stopover: false
                    }
                ]
            },
            3: {
                origin:      {
                    // query: "Amlwch Port",
                    placeId: "ChIJnz-HiYBRZEgRH7i9FTgNuLA"
                },
                destination: {
                    // query: "Greenwood Forest Park, Y Felinheli",
                    placeId: "ChIJ19Bk7f2oZUgR7hx2Vdsrslg"
                },
                waypoints:   [
                    {
                        location: {
                            // query: "Penrhos, Holyhead",
                            placeId: "ChIJxy37E6nKZUgRdZ_lUk7vPMY"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "South Stack Lighthouse, Holyhead",
                            placeId: "ChIJyTKnG680ZEgRxxZQaZDRRBw"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Four Mile Bridge, Holyhead",
                            placeId: "ChIJ-dMFV6XKZUgRrlp_UN_UG0o"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Bryngwran",
                            placeId: "ChIJLUZVdn-0ZUgRh-NHS34u-HI"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Ty Croes",
                            placeId: "ChIJyWRNKk-sZUgR1zv8RjkNoI0"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "sea zoo, Ffordd Brynsiencyn, Llanfairpwllgwyngyll",
                            placeId: "ChIJNRNTTxWvZUgRkyOZ_mQ9SXs"
                        },
                        stopover: false
                    }
                ]
            },
            4: {
                origin:      {
                    // query: "Greenwood Forest Park, Y Felinheli",
                    placeId: "ChIJ19Bk7f2oZUgR7hx2Vdsrslg"
                },
                destination: {
                    // query: "Amgueddfa Lloyd George Museum, Llanystumdwy, Criccieth",
                    placeId: "ChIJMXLQL6qZZUgRYUUI04GHPS8"
                },
                waypoints:   [
                    {
                        location: {
                            // query: "B4547, Y Felinheli",
                            placeId: "EhZCNDU0NywgWSBGZWxpbmhlbGksIFVLIi4qLAoUChIJ13yNskqoZUgRZj4YwWzQ8ZYSFAoSCaF-ElVFB2VIEcmWhnuED4iR"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Llanberis station, Victoria Terrace, Llanberis, Caernarfon",
                            placeId: "ChIJQehl8ToIZUgRe3HG0AXuN3k"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Caernarfon Castle, Castle Ditch, Caernarfon",
                            placeId: "ChIJV9lU8YqlZUgR2njhQ_LUMk0"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Inigo Jones, Caernarfon LL54 7UL",
                            placeId: "ChIJ1yPuiG-kZUgR7OjtzyBLlnY"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Glasfryn Parc, Y Ff%C3%B4r, Pwllheli",
                            placeId: "ChIJc9suqTCWZUgRbDsiQ5om17o"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Nant Gwrtheyrn, Llithfaen, Pwllheli",
                            placeId: "ChIJ5T1auveVZUgRh9wlhjq7OY0"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Aberdaron",
                            placeId: "ChIJnR0dh3zlZUgRinNELRFVLKc"
                        },
                        stopover: false
                    }
                ]
            },
            5: {
                origin:      {
                    // query: "Amgueddfa Lloyd George Museum, Llanystumdwy, Criccieth",
                    placeId: "ChIJMXLQL6qZZUgRYUUI04GHPS8"
                },
                destination: {
                    // query: "Bala Lake Railway, Station Rd, Llanuwchllyn, Bala LL23 7DD",
                    placeId: "ChIJ_QV0-4VCZUgRYyoHU662c0I"
                },
                waypoints:   [
                    {
                        location: {
                            // query: "Porthmadog",
                            placeId: "ChIJ7dCdcVB1ZUgRza-JQhm5Sos"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Portmeirion",
                            placeId: "ChIJGxhoEol1ZUgR9e-sDiykL_g"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Snowdonia National Park Study Centre, Plas Tan y Bwlch, Maentwrog, Blaenau Ffestiniog",
                            placeId: "ChIJJesxJWNzZUgREXqdQwOGauc"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Harlech Castle, Harlech",
                            placeId: "ChIJn6pHNY6dZUgRnVIlkssSd9U"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Penmaenpool Bridge",
                            placeId: "ChIJhaD30O97ZUgRP7YgomIDzQQ"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Talyllyn Railway, Wharf Station, Tywyn",
                            placeId: "ChIJR7AABQx_b0gRwTtoZpT172k"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "Aberdyfi",
                            placeId: "ChIJTU-RTqp4b0gRMA4QTJbIHt4"
                        },
                        stopover: false
                    },
                    {
                        location: {
                            // query: "King Arthur's Labyrinth, Corris Craft Centre, A487 Road, Machynlleth",
                            placeId: "ChIJz_t9t7NiZUgRuZwhaXBgkJE"
                        },
                        stopover: false
                    },
                ]
            }
        }
    };
    state        = {
        activeFilters:    {
            amenities: [],
            location:  undefined,
            member:    undefined,
            pass:      false,
            place:     undefined,
            types:     []
        },
        directionsResponse: undefined,
        filter:           {
            options: {}
        },
        highlightRegion:  undefined,
        kmlFiles:         [],
        locale:           undefined,
        locations:        [],
        locationTypes:    {},
        modalIsVisible:   false,
        region:           false,
        routes:           {},
        selectedLocation: undefined,
        showFilter:       false,
        showFilterPrompt: true,
        showIntro:        false,
        showRoute:        true,
        showRoutePicker:  true
    };

    constructor(props) {
        super(props);

        this.mapOptions = this.props.mapOptions;

        let options = {
            amenities: [],
            types:     []
        };

        this.props.amenities.forEach(
            (amenity, index) => {
                this.amenities[amenity.wordpress_id] = amenity;
                options.amenities.push(amenity);
            }
        )

        let types = {};
        this.props.locationTypes.forEach(
            (type, index) => {
                let typeOption = {
                    class:          type.acf.class,
                    label:          type.acf.label,
                    listing_colour: type.acf.listing_colour,
                    marker_url:     type.acf.marker_icon.localFile.url,
                    value:          type.wordpress_id,
                    children:       [],
                    parent:         undefined,
                    checked:        false,
                    selectedChild:  undefined,
                    translations:   type.translations
                };
                if (type.wordpress_parent === 0) {
                    // top level type
                    if (types.hasOwnProperty(type.wordpress_id)) {
                        // and it has already been initialised
                        // make sure we don't overwrite any children already set
                        typeOption.children = types[type.wordpress_id].children;
                    }
                } else {
                    // nested type
                    typeOption.parent = type.wordpress_parent;
                    if (!types.hasOwnProperty(type.wordpress_parent)) {
                        // parent is not already set up
                        types[type.wordpress_parent] = {
                            children: []
                        };
                    }
                    types[type.wordpress_parent].children.push(type.wordpress_id);
                }
                types[type.wordpress_id] = typeOption;
            }
        );


        Object.keys(types).forEach(
            (id, index) => {
                if (types[id].parent === undefined) {
                    options.types.push(types[id]);
                }
            }
        )
        options.types.sort((a, b) => a.label.localeCompare(b.label));

        this.state.locale         = this.props.locale;
        this.state.filter.options = options;
        this.state.locationTypes  = types;
        this.state.showRoute      = this.props.showRoute;

        if (this.props.hasOwnProperty("showFilterPrompt")) {
            this.state.showFilterPrompt = this.props.showFilterPrompt;
        }
        if (this.props.hasOwnProperty("region")) {
            this.state.region          = this.props.region;
            this.state.highlightRegion = this.props.region;
        }

        let members = [];
        this.props.locations.forEach((location, index) => {
            if (location.translations.current === this.props.locale) {
                if (members.indexOf(location.member.wordpress_id) === -1) {
                    members.push(location.member.wordpress_id);
                    this.members.push(location.member);
                }
            }
        });
        this.members.sort((a, b) => a.name.localeCompare(b.name));

        this.props.regions.forEach((place, index) => {
            if (place.translations.current === this.props.locale) {
                this.regions.push(place);
            }
        });
        this.regions.sort((a, b) => a.title.localeCompare(b.title));
        this.state.kmlFiles = this.getKMLFiles(this.state.highlightRegion);

        let activeFilters = this.state.activeFilters;
        let locationTypes = this.state.locationTypes;
        if (!this.getFiltersActive(activeFilters) && this.props.options.map_default_location_type) {
            activeFilters.types.push(this.props.options.map_default_location_type);
            locationTypes[this.props.options.map_default_location_type].checked = true;
        }

        let parsedUrl = '';
        if (typeof window !== `undefined`) {
            parsedUrl = new URL(window.location.href);

            activeFilters.pass     = parsedUrl.searchParams.get('snowdonia_pass');
            activeFilters.location = parseInt(parsedUrl.searchParams.get('location'));

            let location = false;
            // let index = 0;
            if (activeFilters.location) {
                let index = this.findLocation(activeFilters.location);
                location  = this.props.locations[index];
                if (index !== false && location) {
                    activeFilters.member   = location.member.wordpress_id;
                    activeFilters.location = undefined;
                }
            }

            this.state.activeFilters = activeFilters;
            this.state.locations     = this.filterLocations();

            if (activeFilters.member !== undefined) {
                this.mapOptions.zoom   = 13;
                this.mapOptions.center = {
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude)
                }
                this.state.showIntro   = false;
            } else if (this.state.region) {
                this.mapOptions.zoom   = parseInt(this.state.region.acf.zoom);
                this.mapOptions.center = {
                    lat: parseFloat(this.state.region.acf.latitude),
                    lng: parseFloat(this.state.region.acf.longitude)
                }
            }
        }
    }

    distance = function (first, second) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((second.lat - first.lat) * p) / 2 +
            c(first.lat * p) * c(second.lat * p) *
            (1 - c((second.lng - first.lng) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    filterLocations() {
        let locations = this.props.locations.filter(
            (location, index) => {
                if (location.translations.current !== this.props.locale) {
                    return false;
                }

                // location filter first
                if (this.state.activeFilters.location && location.wordpress_id !== this.state.activeFilters.location) {
                    return false;
                }

                // member filter
                if (this.state.activeFilters.member && location.member.wordpress_id !== this.state.activeFilters.member) {
                    return false;
                }

                // pass filter is next quickest
                if (this.state.activeFilters.pass && !location.member.snowdonia_pass) {
                    return false;
                }

                /**
                 * Location type filtering
                 *
                 * Logic: OR - A location must be ANY of the checked location types
                 */
                if (!location.type) {
                    return false;
                }
                if (this.state.activeFilters.types.length > 0) {
                    let locationTypeMatch = false;
                    for (let i = 0; i < this.state.activeFilters.types.length; i++) {
                        let typeID = this.state.activeFilters.types[i];
                        if (location.type === typeID || typeID === this.state.locationTypes[location.type].parent) {
                            locationTypeMatch = true;
                            break;
                        }
                    }
                    // Avoid doing any more filtering if we've failed the location type filter
                    if (!locationTypeMatch) {
                        return false;
                    }
                }

                /**
                 * Amenities filtering
                 *
                 * Logic: AND - A location must have ALL of the checked amenities to show in the results
                 */
                if (this.state.activeFilters.amenities.length > 0) {
                    for (let i = 0; i < this.state.activeFilters.amenities.length; i++) {
                        let amenityID = this.state.activeFilters.amenities[i];
                        if (!location.member.amenities || !location.member.amenities.includes(amenityID)) {
                            return false;
                        }
                    }
                }

                return true;
            }
        );
        return locations;
    }

    languageFilter = (item) => {
        return !item.hasOwnProperty('translations') || this.props.locale === item.translations.current;
    }

    getFilterWindow() {
        if (this.state.showFilter) {
            return <div id="mapFilterWindow" style={{zIndex: 10, width: "600px"}}>
                <Form className="mapFilterForm">
                    <div className="scroll-wrap">
                        <Button
                            htmlType="button"
                            className="btn btn-white btn-small filter-close"
                            onClick={this.onShowResults}
                        >
                            {language.close[this.props.locale]}
                            <span className="icon close"/>
                        </Button>
                        <Button
                            htmlType="button"
                            className="filter-clear"
                            onClick={this.onClearFilters}
                        >
                            <span>{language.map.filters.clear[this.props.locale]}</span>
                            <span className="icon close"/>
                        </Button>
                        <Form.Item>
                            <Button htmlType="submit"
                                    onClick={this.onShowResults}
                                    className="btn btn-white btn-small"
                            >
                                <span>{language.map.filters.show_results[this.props.locale].replace('%s', this.state.locations.length)}</span>
                                <span className="icon arrow"/>
                            </Button>
                        </Form.Item>
                        <div className="col-1">
                            <div id="filterWhereWrapper">
                                <h4>{language.map.filters.where[this.props.locale]}</h4>
                                <Form.Item label={language.map.filters.place.label[this.props.locale]}>
                                    <Select
                                        dropdownClassName="filterPlaceSelect"
                                        onChange={this.onChangeRegion}
                                        placeholder={language.map.filters.place.placeholder[this.props.locale]}
                                        showSearch
                                        value={this.state.highlightRegion ? this.state.highlightRegion.wordpress_id : ''}
                                    >
                                        <Select.Option key="any"
                                                       value="">{language.map.filters.place.placeholder[this.props.locale]}</Select.Option>
                                        {
                                            this.regions.map((place, index) =>
                                                <Select.Option key={index}
                                                               value={place.wordpress_id}>{place.title}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                                <br />
                                <Form.Item label={language.map.filters.town_or_postcode.label[this.props.locale]}>
                                    <Autocomplete
                                        onLoad={this.onLoadAutocomplete}
                                        onPlaceChanged={this.onAutocompletePlaceChanged}
                                        bounds={this.settings.bounds}
                                        options={{strictBounds: true}}
                                    >
                                        <Input
                                            placeholder={language.map.filters.town_or_postcode.placeholder[this.props.locale]}
                                            onChange={this.onChangePlace}
                                            value={this.state.activeFilters.place}/>
                                    </Autocomplete>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        className="btn btn-white btn-dot"
                                        onClick={this.onClickNearMe}>
                                        <span className="icon locate"></span>
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        className="btn btn-white btn-small"
                                        onClick={this.onClickNearMe}>
                                        {language.map.filters.search[this.props.locale]}
                                        <span className="icon arrow"></span>
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="col-2">
                            <div id="filterTypeWrapper">
                                <h4>{language.map.filters.i_am_looking_for[this.props.locale]}</h4>
                                {
                                    this.state.filter.options.types.filter(this.languageFilter).map(
                                        (type, index) => {
                                            let checkbox = <Form.Item>
                                                <Checkbox key={type.value}
                                                          name="type"
                                                          value={type.value}
                                                          onClick={this.onChangeTypeFilterCheckbox}
                                                          checked={this.state.locationTypes[type.value].checked}>{type.label}</Checkbox>
                                            </Form.Item>;
                                            let select   = null;
                                            if (type.children.length > 0) {
                                                let display  = this.state.locationTypes[type.value].checked ? "block" : "none";
                                                let selected = this.state.locationTypes[type.value].selectedChild ? this.state.locationTypes[type.value].selectedChild : type.value;

                                                select = <Form.Item style={{display: display}}>
                                                    <Select onChange={this.onChangeTypeFilterSelect}
                                                            value={selected}
                                                            dropdownAlign={{offset: [0, 0]}}
                                                    >
                                                        <Select.Option key="all"
                                                                       value={type.value}>{language.map.filters.all[this.props.locale]}</Select.Option>
                                                        {
                                                            type.children.map(
                                                                (id, childIndex) => {
                                                                    let childType = this.state.locationTypes[id];
                                                                    return <Select.Option key={childType.value}
                                                                                          value={childType.value}>{childType.label}</Select.Option>
                                                                }
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            }
                                            return <div key={"typeFilterWrapper" + index}>{checkbox}{select}</div>
                                        }
                                    )
                                }
                            </div>

                            <div id="filterNameWrapper">
                                <br />
                                <p>{language.map.filters.by_name[this.props.locale]}</p>
                                <Form.Item>
                                    <Select
                                        dropdownClassName="filterNameSelect"
                                        showSearch
                                        placeholder="Find by name"
                                        filterOption={(input, option) => {
                                            if (option.props.children === 'any') {
                                                // default option
                                                return false;
                                            }
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        onChange={this.onChangeNameFilter}
                                        value={this.state.activeFilters.member ? this.state.activeFilters.member : ''}
                                    >
                                        <Select.Option key="any"
                                                       value="">{language.map.filters.by_name.default_option[this.props.locale]}</Select.Option>
                                        {
                                            this.members.map((member, index) =>
                                                <Select.Option key={member.wordpress_id}
                                                               value={member.wordpress_id}>{member.name}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className="col-3">
                            <div id="filterAmenitiesWrapper">
                                <h4>{language.map.filters.it_has[this.props.locale]}</h4>
                                {
                                    this.state.filter.options.amenities.filter(this.languageFilter).map(
                                        (amenity, index) => {
                                            return <Checkbox key={"amenity_" + amenity.wordpress_id}
                                                             name="amenities"
                                                             value={amenity.wordpress_id}
                                                             onClick={this.onChangeAmenityFilterCheckbox}
                                                             checked={this.state.activeFilters.amenities.includes(amenity.wordpress_id)}>
                                                {amenity.title}
                                            </Checkbox>
                                        }
                                    )
                                }
                            </div>
                        </div>

                        {/* <div id="filterSnowdoniaPassWrapper">
                            <h4>{language.map.filters.snowdonia_pass[this.props.locale]}</h4>
                            <Form.Item>
                                <Checkbox name="snowdonia_pass"
                                          value="1"
                                          onClick={this.onChangeSnowdoniaPassFilter}
                                          checked={this.state.activeFilters.pass}>
                                    {language.map.filters.snowdonia_pass_checkbox[this.props.locale]}
                                </Checkbox>
                            </Form.Item>
                        </div> */}
                    </div>
                </Form>
            </div>
        }
        return null;
    }

    findLocation = wordpressID => {
        let found = false;
        this.props.locations.forEach((location, index) => {
            if (location.wordpress_id === wordpressID) {
                found = index;
                return false;
            }
        });
        return found;
    }

    getFiltersActive = (activeFilters) => {
        return activeFilters.amenities.length === 0 && activeFilters.pass === false && activeFilters.types.length === 0 && !activeFilters.place && !activeFilters.pass;
    }

    handleGeolocationError = function (browserHasGeolocation) {
        let message = browserHasGeolocation ? 'Error: The Geolocation service failed.' : 'Error: Your browser doesn\'t support geolocation.';
        alert(message);
    }

    nearestLocation = (position) => {
        let nearest = undefined;
        this.state.locations.forEach(
            (location, index) => {
                let locationPosition = {
                    lat: parseFloat(location.latitude),
                    lng: parseFloat(location.longitude)
                };
                let distance         = this.distance(locationPosition, position);
                if (nearest === undefined || distance < nearest.distance) {
                    nearest = {
                        id:       location.wordpress_id,
                        distance: distance,
                        position: locationPosition
                    }
                }
            }
        )
        return nearest;
    }

    onAutocompletePlaceChanged = () => {
        let place = this.autocomplete.getPlace();
        if (place.geometry) {
            let placePosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            }
            let zoom          = 17; // Why 17? Because it looks good.);
            this.panAndZoomMapToPlace(placePosition, zoom);
        }
        this.onChangePlace({target: {value: place.formatted_address}});
    }

    onChangeAmenityFilterCheckbox = (event) => {
        let activeFilters = this.state.activeFilters;
        let amenityID     = parseInt(event.target.value);
        if (event.target.checked) {
            activeFilters.amenities.push(amenityID);
        } else {
            let index = activeFilters.amenities.indexOf(amenityID);
            if (index > -1) {
                activeFilters.amenities.splice(index, 1);
            }
        }

        let locations = this.filterLocations();
        this.setState({
            activeFilters:    activeFilters,
            locations:        locations,
            selectedLocation: undefined,
            // showRoute:        !this.getFiltersActive(activeFilters)
        });
    }

    onChangeNameFilter = (value, option) => {
        let activeFilters    = this.state.activeFilters;
        activeFilters.member = value;

        let locations = this.filterLocations();
        this.setState({
            activeFilters:    activeFilters,
            locations:        locations,
            selectedLocation: undefined
        });
    }

    onChangePlace = (event) => {
        let activeFilters   = this.state.activeFilters;
        activeFilters.place = event.target.value;
        this.setState({activeFilters: activeFilters});
    }

    onChangeRegion = (value, option) => {
        let region = false;
        if (value) {
            for (let i = 0; this.regions.length; i++) {
                if (this.regions[i].wordpress_id === value) {
                    region = this.regions[i];
                    break;
                }
            }
            if (region) {
                this.panAndZoomMapToPlace(
                    {
                        lat: parseFloat(region.acf.latitude),
                        lng: parseFloat(region.acf.longitude)
                    },
                    region.acf.zoom);
                this.setState({
                    highlightRegion: region,
                    kmlFiles:        this.getKMLFiles(region)
                });
            }
        } else {
            // no region selected
            this.panAndZoomMapToPlace(this.props.mapOptions.center, this.props.mapOptions.zoom);
            this.setState({
                highlightRegion: undefined,
                kmlFiles:        this.getKMLFiles(undefined)
            });
        }
    }

    getKMLFiles = (region) => {
        let kmlFiles = [];
        this.props.kmlFiles.map((kml, index) => {
            let url = process.env.GATSBY_GOOGLE_MAP_KML_URL + kml;
            if (region && region.acf.kml_region_file && region.acf.kml_region_file === kml) {
                url += "-highlight";
            }
            kmlFiles.push(url);
        });
        return kmlFiles;
    }

    onChangeRouteLeg = (value, option) => {
        value       = this.settings.routes.hasOwnProperty(value) ? value : false;
        let updates = {showRoute: value};

        // disable the route picker while it is doing a request
        updates.showRoutePicker = (value) ? false : true;
        this.setState(updates);
    }

    onChangeSnowdoniaPassFilter = (event) => {
        let activeFilters  = this.state.activeFilters;
        activeFilters.pass = event.target.checked;

        let locations = this.filterLocations();
        this.setState({
            activeFilters:    activeFilters,
            locations:        locations,
            selectedLocation: undefined,
            // showRoute:        !this.getFiltersActive(activeFilters)
        });
    }

    onChangeTypeFilterCheckbox = (event) => {
        let activeFilters             = this.state.activeFilters;
        let typeID                    = parseInt(event.target.value);
        let locationTypes             = this.state.locationTypes;
        locationTypes[typeID].checked = event.target.checked;

        if (event.target.checked) {
            activeFilters.types.push(typeID);
        } else {
            locationTypes[typeID].selectedChild = undefined;
            let index                           = activeFilters.types.indexOf(typeID);
            if (index > -1) {
                activeFilters.types.splice(index, 1);
            }
            // Remove any active filters for child types
            for (let i = 0; i < this.state.locationTypes[typeID].children.length; i++) {
                index = activeFilters.types.indexOf(this.state.locationTypes[typeID].children[i]);
                if (index > -1) {
                    activeFilters.types.splice(index, 1);
                }
            }
        }

        let locations = this.filterLocations();
        this.setState({
            activeFilters:    activeFilters,
            locations:        locations,
            locationTypes:    locationTypes,
            selectedLocation: undefined,
            // showRoute:        !this.getFiltersActive(activeFilters)
        });
    }

    onChangeTypeFilterSelect = (value, option) => {
        let activeFilters = this.state.activeFilters;
        let locationTypes = this.state.locationTypes;

        if (this.state.locationTypes[value].parent === undefined) {
            // this is an "All" selection specified using the parent type id

            // Remove all of its child types
            if (activeFilters.types.length > 0) {
                activeFilters.types = activeFilters.types.filter((id, index) => {
                    return (this.state.locationTypes[id].parent !== value);
                });
            }

            // Then re-add this top-level type
            activeFilters.types.push(value);
            locationTypes[value].selectedChild = undefined;
        } else {
            activeFilters.types = activeFilters.types.filter((id, index) => {
                if (this.state.locationTypes[value].parent === id) {
                    // remove the parent type
                    return false;
                }
                if (this.state.locationTypes[this.state.locationTypes[value].parent].children.includes(id)) {
                    // remove sibling types
                    return false;
                }
                return true;
            })
            activeFilters.types.push(value);
            locationTypes[locationTypes[value].parent].selectedChild = value;
        }

        let locations = this.filterLocations();
        this.setState({
            activeFilters:    activeFilters,
            locations:        locations,
            locationTypes:    locationTypes,
            selectedLocation: undefined,
            // showRoute:        !this.getFiltersActive(activeFilters)
        });
    }

    onClearFilters = (event) => {
        let locationTypes = this.state.locationTypes;
        Object.keys(locationTypes).forEach((id, index) => {
            locationTypes[id].checked       = false;
            locationTypes[id].selectedChild = undefined;
        });
        this.setState({
            activeFilters:   {
                amenities: [],
                location:  undefined,
                member:    undefined,
                pass:      false,
                place:     undefined,
                types:     []
            },
            highlightRegion: undefined,
            kmlFiles:        this.getKMLFiles(undefined),
            locationTypes:   locationTypes
        });
        this.panAndZoomMapToPlace(this.props.mapOptions.center, this.props.mapOptions.zoom);
    }

    onClickMarker = (event, index) => {
        this.setState({selectedLocation: index, modalIsVisible: true});
    }

    onClickNearMe = (event) => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                var pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                if (this.withinBounds(pos)) {
                    // center the map on this position
                    this.map.setCenter(pos);
                } else {
                    // center the map on the nearest location
                    let location = this.nearestLocation(pos);
                    if (location !== undefined) {
                        this.map.setCenter(location.position);
                    }
                }
            }, () => {
                this.handleGeolocationError(true);
            });
        } else {
            // Browser doesn't support Geolocation
            this.handleGeolocationError(false);
        }
    }

    /*onChangeShowRouteCheckbox = (event) => {
        this.setState({showRoute: event.target.checked});
    }*/

    onCloseInfo = (event) => {
        this.setState({selectedLocation: undefined, modalIsVisible: false});
    }

    onDirectionsResponse = (response) => {
        if (response !== null) {
            if (response.status === 'OK') {
                this.setState({directionsResponse: response});
            }
        }
    }

    onLoadAutocomplete = (autocomplete) => {
        this.autocomplete = autocomplete;
    }

    onLoadMap = (map) => {
        this.map = map;
        this.settings.polylines.forEach((encoded, index) => {
            this.settings.polylines[index] = window.google.maps.geometry.encoding.decodePath(encoded);
        });
        this.doCallback('onLoadMap', [map]);
    }

    doCallback = (key, parameters) => {
        if (this.props.callbacks && this.props.callbacks.hasOwnProperty(key)) {
            this.props.callbacks[key].apply(null, parameters);
        }
    }

    onLoadMarker = (marker, index) => {
        this.markers[index] = marker;
        if (this.markers.length === this.state.locations.length) {
            // We force an update here to show the pre-selected location
            this.forceUpdate();
        }
    }

    onMapChanged = event => {
        if (this.map && this.state.showIntro) {
            this.setState({showIntro: false});
        }
    }

    onShowResults = event => {
        event.preventDefault();
        this.toggleFilterWindow(event);
    }

    panAndZoomMapToPlace = (placePosition, zoomLevel) => {
        this.map.setCenter(placePosition);
        let nearestLocation = this.nearestLocation(placePosition);
        this.map.setZoom(parseInt(zoomLevel));
        let bounds  = this.map.getBounds();
        let limiter = 0;

        while (!bounds.contains(nearestLocation.position) && limiter < 20) {
            zoomLevel--;
            limiter++;
            this.map.setZoom(zoomLevel);
            bounds = this.map.getBounds();
        }
    }

    render() {
        return (
            <div>
                {this.state.showFilterPrompt && (
                    <div className="filter-results">
                        <h2>{language.map.filters.filter_heading[this.props.locale]}</h2>
                        <p>{language.map.filters.filter_text[this.props.locale]}</p>
                        <Button
                            onClick={this.toggleFilterWindow}>{language.map.filters.filter_results[this.props.locale]}
                            <span
                                className="icon"/></Button>
                    </div>
                )}
                <a name="map"/>
                {this.state.showIntro && (
                    <div className="framed-content">
                        <div className="frame-con-top"
                             dangerouslySetInnerHTML={{__html: language.homePage.discoverMessage[this.props.locale]}}/>
                        <div className="frame-con-bottom">
                            {language.homePage.routeSponsor[this.props.locale]}
                        </div>
                    </div>
                )}
                <LoadScriptNext id="script-loader"
                                googleMapsApiKey={process.env.GATSBY_GOOGLE_MAP_API_KEY}
                                language={this.props.locale}
                                libraries={this.loadScriptLibraries}
                                region="GB"
                >
                    <GoogleMap
                        id='snowdonia-map'
                        {...this.mapOptions}
                        onLoad={this.onLoadMap}
                        onZoomChanged={this.onMapChanged}
                        onCenterChanged={this.onMapChanged}
                    >
                        <MarkerClusterer
                            options={this.props.clustererOptions}
                        >
                            {
                                (clusterer) => this.state.locations
                                                   .map(
                                                       (location, index) => {
                                                           return <Marker
                                                               key={index}
                                                               position={({
                                                                   lat: parseFloat(location.latitude),
                                                                   lng: parseFloat(location.longitude)
                                                               })}
                                                               clusterer={clusterer}
                                                               icon={this.state.locationTypes[location.type].marker_url}
                                                               onClick={event => this.onClickMarker(event, index)}
                                                               onLoad={event => this.onLoadMarker(event, index)}
                                                               title={location.member.name}
                                                           >
                                                           </Marker>
                                                       }
                                                   )
                            }
                        </MarkerClusterer>
                        {this.state.selectedLocation !== undefined && this.markers[this.state.selectedLocation] && (
                            <Modal
                                className="map-modal"
                                title={null}
                                visible={this.state.modalIsVisible}
                                footer={null}
                                closable={true}
                                position={this.markers[this.state.selectedLocation].position}
                                onCancel={this.onCloseInfo}
                                key={'infoBox_' + this.state.selectedLocation}
                                options={{
                                    enableEventPropagation: true,
                                    pixelOffset:            {width: 0, height: -300}
                                }}
                            >
                                <div className="member-listing-item"
                                     data-color={this.state.locationTypes[this.state.locations[this.state.selectedLocation].type].listing_colour}>
                                    {this.state.locations[this.state.selectedLocation].member.featured_image && (
                                        <Img
                                            fluid={this.state.locations[this.state.selectedLocation].member.featured_image.localFile.childImageSharp.fluid}/>
                                    )}
                                    {/* End WP image */}
                                    <span className="content-wrap">
                                        <span className="title">
                                            {this.state.locations[this.state.selectedLocation].member.name}
                                        </span>
                                        <span className="desc">
                                            {this.state.locations[this.state.selectedLocation].member.snippet}
                                        </span>
                                        {parseInt(this.state.locations[this.state.selectedLocation].member.membership_level) < 3 && (
                                            <>
                                                <Link className="more"
                                                      to={`${this.state.locations[this.state.selectedLocation].member.path}?location=${this.state.locations[this.state.selectedLocation].wordpress_id}`}
                                                      onClick={() => trackViewMember(`${this.state.locations[this.state.selectedLocation].member.path}?location=${this.state.locations[this.state.selectedLocation].wordpress_id}`)}>
                                                    {language.map.info_window.read_more[this.props.locale]}

                                                </Link>
                                                <MyTripContext.Consumer>
                                                    {({addLocation}) => (
                                                        <Link className="btn btn-primary" onClick={() => {
                                                            this.setState({
                                                                modalIsVisible: false
                                                            });
                                                            addLocation(this.state.locations[this.state.selectedLocation].wordpress_id)
                                                        }}>
                                                            <span>{language.map.info_window.add_to_trip[this.props.locale]}</span>
                                                            <span className="icon add"></span>
                                                        </Link>
                                                    )}
                                                </MyTripContext.Consumer>
                                            </>
                                        )}
                                        {parseInt(this.state.locations[this.state.selectedLocation].member.membership_level) === 3 && (
                                            <>
                                                <Link className="more"
                                                      to={this.state.locations[this.state.selectedLocation].member.website_url}
                                                      onClick={() => trackViewMember(this.state.locations[this.state.selectedLocation].member.website_url)}>
                                                    {language.map.info_window.visit_website[this.props.locale]}
                                                    <span className="icon"></span>
                                                </Link>
                                                <MyTripContext.Consumer>
                                                    {({addLocation}) => (
                                                        <Link className="btn btn-primary" onClick={() => {
                                                            addLocation(this.state.locations[this.state.selectedLocation].wordpress_id)
                                                        }}>
                                                            <span>{language.map.info_window.add_to_trip[this.props.locale]}</span>
                                                            <span className="icon add"></span>
                                                        </Link>
                                                    )}
                                                </MyTripContext.Consumer>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </Modal>
                        )}
                        {this.map && this.state.showRoute && this.props.kmlFiles && (
                            <>
                                {
                                    this.state.kmlFiles.map((url) => {
                                        return <KmlLayer key={"kml-" + url}
                                                         url={url + ".kml?refresh=17"}
                                                         options={{preserveViewport: true, suppressInfoWindows: true}}/>
                                    })
                                }
                            </>
                        )}

                        {this.props.directions
                        && (
                            <>
                                <DirectionsService
                                    // required
                                    options={this.props.directions}
                                    // required
                                    callback={this.onDirectionsResponse}
                                />

                                {this.state.directionsResponse &&
                                    <DirectionsRenderer
                                        directions={this.state.directionsResponse}
                                        options={{
                                            panel:             document.getElementById('directionsText'),
                                            polylineOptions:   {
                                                strokeColor:   '#C60F7B',
                                                strokeOpacity: 0.7,
                                                strokeWeight:  6,
                                            },
                                            suppressPolylines: false
                                        }}
                                    />
                                }
                            </>
                        )}
                    </GoogleMap>
                </LoadScriptNext>
                {this.getFilterWindow()}
                <div id="directionsText"></div>
            </div>
        )
    }

    toggleFilterWindow = (event) => {
        // opening the filter removes the location filter
        let activeFilters   = this.state.activeFilters;
        let locations       = this.state.locations;
        let updateLocations = false;
        if (this.state.activeFilters.location !== undefined) {
            activeFilters.location = undefined;
            updateLocations        = true;
        }
        // if (this.state.activeFilters.member !== undefined) {
        //     activeFilters.member = undefined;
        //     updateLocations      = true;
        // }
        if (updateLocations) {
            locations = this.filterLocations();
        }
        this.setState({
            activeFilters: activeFilters,
            locations:     locations,
            showFilter:    !this.state.showFilter
        });
    }

    withinBounds = (position) => {
        return position.lat >= this.settings.bounds.south
            && position.lat <= this.settings.bounds.north
            && position.lng >= this.settings.bounds.west
            && position.lng <= this.settings.bounds.east;
    }
}

Map.defaultProps = {
    callbacks:        [],
    clustererOptions: {
        gridSize:           20,
        imagePath:          "/map/marker_cluster_",
        imageSizes:         [37, 37, 37, 37, 37],
        maxZoom:            10,
        minimumClusterSize: 3
    },
    kmlFiles:         [
        "Anglesey",
        "Llyn_Peninsula",
        "North_Coast",
        "North_Snowdonia",
        "South_Snowdonia"
    ],
    locale:           'en',
    mapOptions:       {
        center:            {
            lat: 53,
            lng: -4.6
        },
        zoom:              9,
        mapContainerStyle: {
            height: "400px",
            width:  "100%"
        },
        options:           {
            mapTypeControl:    false,
            streetViewControl: false,
            fullscreenControl: false,
            styles:            [
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry.fill",
                    "stylers":     [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers":     [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers":     [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers":     [
                        {
                            "color": "#dbdbdb"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels",
                    "stylers":     [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "stylers":     [
                        {
                            "color": "#3c525d"
                        }
                    ]
                }
            ]
        }
    },
    showRoute:        true
}

export default Map;
